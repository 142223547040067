@import 'variables.scss';


.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-body-section {
  background-color: $background-primary;
  min-height: 100vh;
}

//Global CSS

.text-center {
  display: flex;
  text-align: center;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}