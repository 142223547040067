@import '../../variables.scss';

.header {
    background: $background-primary;
    display: flex;
    color: $black;
    height: calc(100vw * (123 / 1920)); // 123px;
    justify-content: space-around;
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100vw;
    box-shadow: 0px 4px 8px #00000029;

    .header-container {
        width: calc(100vw * (1640 / 1920)); // calc(100vw - 48px);
        margin: 0 calc(100vw * (140 / 1920));
        display: flex;
        justify-content: space-between;

        .logo {
            margin: auto 0 auto calc(100vw * (25 / 1920)); //25px;
            cursor: pointer;
            max-width: calc(100vw * (260 / 1920)); //260px;
            max-height: calc(100vw * (56 / 1920)); //56px;
            width: 260px;
            height: 56px;
        }

        .brands {
            display: flex;

            .brand {
                margin: auto calc(100vw * (32 / 1920)) auto 0;
                cursor: pointer;

                picture{
                    display: contents;
                    .icon {
                    max-width: calc(100vw * (166.5 / 1920)); //166.5px;
                    max-height: calc(100vw * (42 / 1920)); //42px;
                }}

                .fgi {
                    margin-left: 5px;
                }
            }

            .text {
                font-family: 'DIN-Condensed-Bold';
                // @include DINCondensedBold();
                // font: normal normal bold 16px/22px DIN Condensed;
                font-size: calc(100vw * (22 / 1920)); //22px;
                color: $text-primary;
            }

            .search {
                margin: auto;

                .fgi {
                    font-size: calc(100vw * (24 / 1920)); //32px;
                }
            }
        }

        .user {
            display: flex;
            margin: auto 0;
            text-align: right;
            flex-flow: column;

            .user-name {
                font-size: calc(100vw * (18 / 1920)); //18px;
                line-height: calc(100vw * (30 / 1920)); //30px;
                font-family: 'Helvetica-Bold';
                letter-spacing: 0px;
                color: $text-primary;
            }

            .user-logout {
                margin-top: calc(100vw * (-8 / 1920)); //-8px;
                font-size: calc(100vw * (12 / 1920)); //12px;
                line-height: calc(100vw * (30 / 1920)); //30px;
                font-family: 'Helvetica';
                letter-spacing: 0px;
                color: $action;
                cursor: pointer;
            }
        }

    }

    .header-container-mobile {
        display: none;
    }
}


.fade-menu {
    .MuiPaper-root {
        background: #EFEFED 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #33333380;
        border-radius: 0;
        margin-left: calc(-100vw * (32 / 1920));
        margin-top: calc(100vw * (9 / 1920));

        .MuiMenu-list {
            padding: 0;

            >li {
                padding: 15px;
                border: 1px solid #33333380;

                picture{
                    display: contents;
                    .icon {
                    max-width: calc(100vw * (87 / 1920)); //87px;
                    max-height: calc(100vw * (42 / 1920)); //42px;
                    margin: auto;
                }}
            }
        }
    }


}

.search-dialog {
    .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.87) !important;
    }

    .search-dialog-input-container {
        width: calc(100vw * (674 / 1920)); //674px;
        height: calc(100vw * (71 / 1920)); //71px;
    }

    #search-input {
        height: calc(100vw * (42 / 1920)); //42px;
        font-size: calc(100vw * (42 / 1920)); //42px;
        line-height: calc(100vw * (48 / 1920)); //48px;
        font-family: 'DIN-Condensed-Bold';
        margin: calc(100vw * (12 / 1920)) calc(100vw * (20 / 1920)); // 12px 20px;
        padding: 0;
        text-transform: uppercase;
    }

    >div {
        align-items: baseline;

        >div {
            max-width: calc(100vw * (674 / 1920)); //674px;
            margin-top: calc(100vw * (215 / 1920)); //215px;
        }
    }

    .search-input-action {
        .fgi {
            font-size: calc(100vw * (32 / 1920)); //32px;
        }
    }
}

@media (max-width: 1079px) {
    .header {
        height: 84px;
        background: #EFEFED 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 8px #00000029;
        opacity: 1;

        .header-container {
            display: none;
        }

        .header-container-mobile {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .logo {
                margin: auto;
                width: 168px;
                height: 36px;
            }

            .search {
                margin: auto;
                margin-right: 31px;

                .fgi {
                    font-size: 24px;
                }
            }

            .more {
                margin: auto;
                margin-left: 31px;

                .fgi {
                    font-size: 24px;
                }
            }
        }
    }

    .menu-dialog {
        margin-top: 84px;

        >div {
            top: 84px;

            .brands-mobile {
                display: flex;
                flex-direction: column;

                .brand-mobile {
                    display: flex;
                    width: 100%;
                    height: 100px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border-top: 1px solid #D1D1D1;
                    border-bottom: 1px solid #D1D1D1;
                    opacity: 1;
                    cursor: pointer;

                   picture{
                    display: contents;
                     .icon {
                        max-width: 166.5px;
                        max-height: 42px;
                        margin: auto;
                    }
                }
                }

                .text {
                    text-align: center;
                    font: normal normal bold 24px/34px 'DIN-Condensed-Bold';
                    letter-spacing: 0px;
                    color: #333333;
                    opacity: 1;
                    margin: auto;
                }


            }

            .logout-mobile {
                height: 50px;
                background: #3273B7 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #D1D1D1;
                opacity: 1;
                border-radius: 0px;
                font: normal normal normal 15px / 18px 'DIN-Condensed-Bold';
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: capitalize;
            }
        }
    }

    .search-dialog {
        margin-top: 84px;

        .search-dialog-input-container {
            width: calc(100vw * (358 / 430)); //674px;
            height: 71px;
        }

        #search-input {
            height: 32px;
            font-size: 32px;
            line-height: 48px;
            font-family: 'DIN-Condensed-Bold';
            margin: 15px 20px;
            padding: 0;
            text-transform: uppercase;
        }

        >div {
            top: 84px;

            >div {
                max-width: calc(100vw * (358 / 430)); //674px;
            }
        }

        .search-input-action {
            .fgi {
                font-size: 32px;
            }
        }
    }
}

@media (min-width: 1920px) {

    .header {
        height: 123px;

        .header-container {
            width: 1640px;

            .logo {
                margin: auto 0 auto 25px;
            }

            .brands {
                .brand {
                    margin: auto 32px auto 0;
                    cursor: pointer;

                   picture{ 
                    display: contents;
                    .icon {
                        max-width: 166.5px;
                        max-height: 42px;
                    }
                }
                }

                .text {
                    font-size: 22px;
                }

                .search {
                    .fgi {
                        font-size: 24px;
                    }
                }
            }

            .user {

                .user-name {
                    font: normal normal bold 18px/30px 'Helvetica-Bold';
                }

                .user-logout {
                    margin-top: -8px;
                    font: normal normal normal 12px/30px 'Helvetica';
                    letter-spacing: 0px;
                }
            }

        }
    }

    .fade-menu {
        .MuiPaper-root {
            margin-left: -32px;
            margin-top: 9px;

            .MuiMenu-list {
                >li {
                    picture{
                        display: contents;
                    .icon {
                        max-width: 87px;
                        max-height: 42px;
                    }}
                }
            }
        }


    }

    .search-dialog {
        .search-dialog-input-container {
            width: 674px;
            height: 71px;
        }

        #search-input {
            height: 42px;
            font-size: 42px;
            line-height: 48px;
            margin: 12px 20px;
        }

        >div {
            >div {
                max-width: 674px;
                margin-top: 215px;
            }
        }

        .search-input-action {
            .fgi {
                font-size: 32px;
            }
        }
    }
}