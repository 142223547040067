// color
$background-primary: #EBEBEC;
$layer-text-primary: #FFFFFF;
$text-primary: #333333;
$divider-primary : #D1D1D1;
$black: #000;
$white: #FFFFFF;
$action: #3273B7;

@font-face {
    font-family: 'DIN-Condensed-Bold';
    src: url('./fonts/DIN-Condensed-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Helvetica-Bold';
    src: url('./fonts/helvetica-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica';
    src: url('./fonts/helvetica.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
